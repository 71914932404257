<template>
    <div>
        <div class="top_title">管理制度公告</div>
        <div class="notice_main">
            <div class="tit">管理制度公告</div>
            <div class="notice_con">
                <p>测试测试测试</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'Notice'
}
</script>

<style lang="less" scoped>
    #app{
        background: #fff;
    }
    .top_title{
        font-size: .3rem;
        color: #333;
        padding: .3rem;
        border-bottom: 1px solid #ddd;
    }
    .notice_main{
        padding: .3rem;
        .tit{
            font-size: .28rem;
            font-weight: bold;
            color: #333;
            text-align: center;
            margin-bottom: .3rem;
        }
        .notice_con{
            font-size: .24rem;
            color: #666;

        }
    }
</style>